import "../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faBars,
  faCircleXmark,
  faGear,
  faRightFromBracket,
  faPencil,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import ailogo from "../assets/images/ailogo.png";
import {
  retrieveHistroy,
  chatSelector,
  clearData,
} from "../store/reducer/ChatReducer";
import _ from "lodash";

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** States */
  const [show, setShow] = useState(false);
  const [menushow, setmenuShow] = useState(false);
  const handlemenuShow = () => setmenuShow(!menushow);
  const name = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const [todayData, settodayData] = useState([]);
  const [yesterdaysData, setyesterdaysData] = useState([]);
  const [sevendaysData, setsevendaysData] = useState([]);
  /** Selectors */
  const { retrieveData, classifyData, retrieveDataLoading, errorMessage } =
    useSelector(chatSelector);

  /** useEffect */
  useEffect(() => {
    dispatch(retrieveHistroy({ email }));
  }, []);
  useEffect(() => {
    dispatch(retrieveHistroy({ email }));
  }, [classifyData]);
  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastSevenDays = new Date(today);
    lastSevenDays.setDate(lastSevenDays.getDate() - 7);

    const structuredObjects = _.groupBy(retrieveData, (item) => {
      const itemDate = new Date(item.date);
      if (itemDate.toDateString() === today.toDateString()) {
        return "Today";
      } else if (itemDate.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return "lastSevenDays";
      }
      //  else {
      //   return `${itemDate.toLocaleString('default', { month: 'long' })} ${itemDate.getFullYear()}`;
      // }
    });
    console.log(structuredObjects, "ASDFASDF");
    settodayData(_.reverse(structuredObjects.Today));
    setyesterdaysData(_.reverse(structuredObjects.Yesterday));
    setsevendaysData(_.reverse(structuredObjects.lastSevenDays));
  }, [retrieveData]);

  console.log(todayData, "classifyData1");

  return (
    <>
      <section>
        <Row>
          <Col lg={12} md={12} sm={12} className="p-0">
            <div className="purple-bg">
              <div>
                <FontAwesomeIcon
                  className="hambergericon"
                  onClick={handlemenuShow}
                  icon={faBars}
                />
              </div>

              <Col
                onClick={() => {
                  navigate("/Chat");
                }}
              >
                <div className="d-flex justify-content-around p-5 pt-0 pb-0">
                  <FontAwesomeIcon
                    className="profileicons mt-2"
                    icon={faPencil}
                  />
                  <h3 className="">New Chat</h3>
                </div>
              </Col>
              <Col>
                <div className="ulli">
                  <ul>
                    <hr />
                    <h3>{todayData ? "Today" : ""}</h3>
                    {todayData?.map((currentData, Index) => {
                      return (
                        <li
                          onClick={() =>
                            navigate(`/Chat/${currentData?.u_key}`)
                          }
                        >
                          {currentData?.question}
                          <hr />
                        </li>
                      );
                    })}
                    <h3>{yesterdaysData ? "Yesterday" : ""}</h3>
                    {yesterdaysData?.map((yesterdayData, Index) => {
                      return (
                        <li
                          onClick={() =>
                            navigate(`/Chat/${yesterdayData?.u_key}`)
                          }
                        >
                          {yesterdayData?.question}
                          <hr />
                        </li>
                      );
                    })}                    
                    <h3>
                      {sevendaysData ? "<h1>Last 7 Days</h1>" : ""}
                    </h3>
                    {sevendaysData?.map((lastSevenDaysData, Index) => {
                      return (
                        <li
                          onClick={() =>
                            navigate(`/Chat/${lastSevenDaysData?.u_key}`)
                          }
                        >
                          {lastSevenDaysData?.question}
                          <hr />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Col>
              {/* <Row clas> */}
              <div className="d-flex justify-content-between p-5">
                <h3 className="">{name}</h3>
                <FontAwesomeIcon
                  className="profileicons mt-2"
                  icon={faRightFromBracket}
                  onClick={() => {
                    localStorage.clear();
                    navigate("/Login");
                  }}
                />
              </div>
              {/* </Row> */}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default Sidebar;
