import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Intro from "./components/Intro";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Chat from "./components/Chat";
import Profile from "./components/Profile";
import Openai from "./components/Openai";
import Sidebar from "./components/Sidebar";
import File from "./components/File";
import PrivateRoute from "./PrivateRoute";
import LawyerLogin from "./components/LawyerLogin";
import LawyerSignup from "./components/LaywerSignup";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/LawyerLogin" element={<LawyerLogin />} />
          <Route path="/LawyerSignup" element={<LawyerSignup />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/" Component={PrivateRoute}>
            <Route path="/Chat" element={<Chat />} />
            <Route path="/Chat/:chatkey" element={<Chat />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Openai" element={<Openai />} />
            <Route path="/Sidebar" element={<Sidebar />} />
            <Route path="/File" element={<File />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
