import "../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowUp,
  faBriefcase,
  faCircleXmark,
  faGear,
  faLocationDot,
  faRightFromBracket,
  faStar,
  faUser,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import ailogo from "../assets/images/ailogo.png";
import lawyerlist from "../assets/images/lawyerlist.jpg";
import Sidebar from "./Sidebar";

function Openai() {
  const navigate = useNavigate();
  const handlerFuntion = (event) => {
    if (event.key == "Enter") {
      console.log(event.target.value, "tests");
    }
  };
  const [show, setShow] = useState(false);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <section>
        <Row>
          <Col xl={2} lg={3} md={1} sm={12} className="p-0">
            <Sidebar> </Sidebar>
          </Col>

          <Col xl={10} lg={9} md={11} sm={12} className="p-0">
            <div className="black-bg">
              <Row>
                <Col lg={2} md={1} className="p-0">
                  <div className="empty-div"></div>{" "}
                </Col>
                <Col lg={7} md={10} className="p-0">
                  <div className="relative search-height">
                    <div className="bg-card">
                      {" "}
                      <Row className="msclass">
                        <Col className="searchtext">
                          <Form.Control
                            className="border-none"
                            onKeyDown={(e) => {
                              handlerFuntion(e);
                            }}
                            type="text"
                            placeholder="Message AI Lawyer"
                          />
                        </Col>
                        <Col>
                          {" "}
                          <FontAwesomeIcon
                            className="enter-button"
                            icon={faArrowUp}
                          />{" "}
                        </Col>
                      </Row>{" "}
                    </div>
                    <div className="chat-logo">
                      {" "}
                      <img src={ailogo} /> <p>How can I help you? </p>
                    </div>
                    <div className="lawyerchat">
                      <div className="relative">
                        {" "}
                        <h6>
                          {" "}
                          <FontAwesomeIcon
                            className="lawyerchaticonone"
                            icon={faUser}
                          />{" "}
                          You{" "}
                        </h6>
                        <p>
                          {" "}
                          How does the legal system define and address the
                          concept of self-defense in criminal cases ?{" "}
                        </p>
                      </div>
                      <div className="relative">
                        {" "}
                        <h6>
                          {" "}
                          <div className="lawyerchaticon"> </div>
                          AI Lawyer{" "}
                        </h6>
                      </div>
                      <p>
                        {" "}
                        Legal Requirements for Forming a Valid Will:
                        Testamentary Capacity: The testator (person making the
                        will) must be of sound mind and understanding, meaning
                        they understand the nature of their actions and the
                        consequences of their decisions. Intent: The testator
                        must have the intent to create a will and dispose of
                        their property upon their death. Voluntariness: The will
                        must be made voluntarily, without any coercion or undue
                        influence from others. In Writing: In most
                        jurisdictions, a will must be in writing, either typed
                        or handwritten, and signed by the testator or by someone
                        else at the testator's direction and in their presence.
                        Witnesses: Many jurisdictions require the will to be
                        witnessed by at least two competent witnesses who are
                        not beneficiaries of the will. Signature: The testator's
                        signature must be present at the end of the will or in a
                        place where it is clear that the signature was intended
                        to authenticate the document.{" "}
                      </p>{" "}
                      <p>
                        {" "}
                        Probate Process: After the testator's death, the will is
                        submitted to probate court, where its validity is
                        determined. The court oversees the distribution of the
                        testator's assets according to the terms of the will.
                        Executor: The executor, named in the will, is
                        responsible for administering the estate. They gather
                        assets, pay debts and taxes, and distribute assets
                        according to the terms of the will. Challenges to the
                        Will: Probate law allows interested parties to challenge
                        the validity of the will if they believe it does not
                        meet the legal requirements or if they suspect undue
                        influence, fraud, or lack of testamentary capacity.
                        Intestate Succession: If someone dies without a valid
                        will (intestate), probate law dictates how their assets
                        will be distributed among their heirs according to the
                        laws of intestate succession, which vary by
                        jurisdiction. Distribution of Assets: Once all debts,
                        taxes, and expenses are paid, the remaining assets are
                        distributed to the beneficiaries named in the will. If
                        there are no beneficiaries or if the will does not
                        specify how assets should be distributed, they may be
                        distributed according to default rules established by
                        probate law.{" "}
                      </p>
                      <p>
                        {" "}
                        Legal Requirements for Forming a Valid Will:
                        Testamentary Capacity: The testator (person making the
                        will) must be of sound mind and understanding, meaning
                        they understand the nature of their actions and the
                        consequences of their decisions. Intent: The testator
                        must have the intent to create a will and dispose of
                        their property upon their death. Voluntariness: The will
                        must be made voluntarily, without any coercion or undue
                        influence from others. In Writing: In most
                        jurisdictions, a will must be in writing, either typed
                        or handwritten, and signed by the testator or by someone
                        else at the testator's direction and in their presence.
                        Witnesses: Many jurisdictions require the will to be
                        witnessed by at least two competent witnesses who are
                        not beneficiaries of the will. Signature: The testator's
                        signature must be present at the end of the will or in a
                        place where it is clear that the signature was intended
                        to authenticate the document.{" "}
                      </p>{" "}
                      <p>
                        {" "}
                        Probate Process: After the testator's death, the will is
                        submitted to probate court, where its validity is
                        determined. The court oversees the distribution of the
                        testator's assets according to the terms of the will.
                        Executor: The executor, named in the will, is
                        responsible for administering the estate. They gather
                        assets, pay debts and taxes, and distribute assets
                        according to the terms of the will. Challenges to the
                        Will: Probate law allows interested parties to challenge
                        the validity of the will if they believe it does not
                        meet the legal requirements or if they suspect undue
                        influence, fraud, or lack of testamentary capacity.
                        Intestate Succession: If someone dies without a valid
                        will (intestate), probate law dictates how their assets
                        will be distributed among their heirs according to the
                        laws of intestate succession, which vary by
                        jurisdiction. Distribution of Assets: Once all debts,
                        taxes, and expenses are paid, the remaining assets are
                        distributed to the beneficiaries named in the will. If
                        there are no beneficiaries or if the will does not
                        specify how assets should be distributed, they may be
                        distributed according to default rules established by
                        probate law.{" "}
                      </p>
                      <p>
                        {" "}
                        Legal Requirements for Forming a Valid Will:
                        Testamentary Capacity: The testator (person making the
                        will) must be of sound mind and understanding, meaning
                        they understand the nature of their actions and the
                        consequences of their decisions. Intent: The testator
                        must have the intent to create a will and dispose of
                        their property upon their death. Voluntariness: The will
                        must be made voluntarily, without any coercion or undue
                        influence from others. In Writing: In most
                        jurisdictions, a will must be in writing, either typed
                        or handwritten, and signed by the testator or by someone
                        else at the testator's direction and in their presence.
                        Witnesses: Many jurisdictions require the will to be
                        witnessed by at least two competent witnesses who are
                        not beneficiaries of the will. Signature: The testator's
                        signature must be present at the end of the will or in a
                        place where it is clear that the signature was intended
                        to authenticate the document.{" "}
                      </p>{" "}
                      <p>
                        {" "}
                        Probate Process: After the testator's death, the will is
                        submitted to probate court, where its validity is
                        determined. The court oversees the distribution of the
                        testator's assets according to the terms of the will.
                        Executor: The executor, named in the will, is
                        responsible for administering the estate. They gather
                        assets, pay debts and taxes, and distribute assets
                        according to the terms of the will. Challenges to the
                        Will: Probate law allows interested parties to challenge
                        the validity of the will if they believe it does not
                        meet the legal requirements or if they suspect undue
                        influence, fraud, or lack of testamentary capacity.
                        Intestate Succession: If someone dies without a valid
                        will (intestate), probate law dictates how their assets
                        will be distributed among their heirs according to the
                        laws of intestate succession, which vary by
                        jurisdiction. Distribution of Assets: Once all debts,
                        taxes, and expenses are paid, the remaining assets are
                        distributed to the beneficiaries named in the will. If
                        there are no beneficiaries or if the will does not
                        specify how assets should be distributed, they may be
                        distributed according to default rules established by
                        probate law.{" "}
                      </p>
                      <p>
                        {" "}
                        Legal Requirements for Forming a Valid Will:
                        Testamentary Capacity: The testator (person making the
                        will) must be of sound mind and understanding, meaning
                        they understand the nature of their actions and the
                        consequences of their decisions. Intent: The testator
                        must have the intent to create a will and dispose of
                        their property upon their death. Voluntariness: The will
                        must be made voluntarily, without any coercion or undue
                        influence from others. In Writing: In most
                        jurisdictions, a will must be in writing, either typed
                        or handwritten, and signed by the testator or by someone
                        else at the testator's direction and in their presence.
                        Witnesses: Many jurisdictions require the will to be
                        witnessed by at least two competent witnesses who are
                        not beneficiaries of the will. Signature: The testator's
                        signature must be present at the end of the will or in a
                        place where it is clear that the signature was intended
                        to authenticate the document.{" "}
                      </p>{" "}
                      <p>
                        {" "}
                        Probate Process: After the testator's death, the will is
                        submitted to probate court, where its validity is
                        determined. The court oversees the distribution of the
                        testator's assets according to the terms of the will.
                        Executor: The executor, named in the will, is
                        responsible for administering the estate. They gather
                        assets, pay debts and taxes, and distribute assets
                        according to the terms of the will. Challenges to the
                        Will: Probate law allows interested parties to challenge
                        the validity of the will if they believe it does not
                        meet the legal requirements or if they suspect undue
                        influence, fraud, or lack of testamentary capacity.
                        Intestate Succession: If someone dies without a valid
                        will (intestate), probate law dictates how their assets
                        will be distributed among their heirs according to the
                        laws of intestate succession, which vary by
                        jurisdiction. Distribution of Assets: Once all debts,
                        taxes, and expenses are paid, the remaining assets are
                        distributed to the beneficiaries named in the will. If
                        there are no beneficiaries or if the will does not
                        specify how assets should be distributed, they may be
                        distributed according to default rules established by
                        probate law.{" "}
                      </p>
                      <p>
                        {" "}
                        Legal Requirements for Forming a Valid Will:
                        Testamentary Capacity: The testator (person making the
                        will) must be of sound mind and understanding, meaning
                        they understand the nature of their actions and the
                        consequences of their decisions. Intent: The testator
                        must have the intent to create a will and dispose of
                        their property upon their death. Voluntariness: The will
                        must be made voluntarily, without any coercion or undue
                        influence from others. In Writing: In most
                        jurisdictions, a will must be in writing, either typed
                        or handwritten, and signed by the testator or by someone
                        else at the testator's direction and in their presence.
                        Witnesses: Many jurisdictions require the will to be
                        witnessed by at least two competent witnesses who are
                        not beneficiaries of the will. Signature: The testator's
                        signature must be present at the end of the will or in a
                        place where it is clear that the signature was intended
                        to authenticate the document.{" "}
                      </p>{" "}
                      <p>
                        {" "}
                        Probate Process: After the testator's death, the will is
                        submitted to probate court, where its validity is
                        determined. The court oversees the distribution of the
                        testator's assets according to the terms of the will.
                        Executor: The executor, named in the will, is
                        responsible for administering the estate. They gather
                        assets, pay debts and taxes, and distribute assets
                        according to the terms of the will. Challenges to the
                        Will: Probate law allows interested parties to challenge
                        the validity of the will if they believe it does not
                        meet the legal requirements or if they suspect undue
                        influence, fraud, or lack of testamentary capacity.
                        Intestate Succession: If someone dies without a valid
                        will (intestate), probate law dictates how their assets
                        will be distributed among their heirs according to the
                        laws of intestate succession, which vary by
                        jurisdiction. Distribution of Assets: Once all debts,
                        taxes, and expenses are paid, the remaining assets are
                        distributed to the beneficiaries named in the will. If
                        there are no beneficiaries or if the will does not
                        specify how assets should be distributed, they may be
                        distributed according to default rules established by
                        probate law.{" "}
                      </p>
                      <p>
                        {" "}
                        Legal Requirements for Forming a Valid Will:
                        Testamentary Capacity: The testator (person making the
                        will) must be of sound mind and understanding, meaning
                        they understand the nature of their actions and the
                        consequences of their decisions. Intent: The testator
                        must have the intent to create a will and dispose of
                        their property upon their death. Voluntariness: The will
                        must be made voluntarily, without any coercion or undue
                        influence from others. In Writing: In most
                        jurisdictions, a will must be in writing, either typed
                        or handwritten, and signed by the testator or by someone
                        else at the testator's direction and in their presence.
                        Witnesses: Many jurisdictions require the will to be
                        witnessed by at least two competent witnesses who are
                        not beneficiaries of the will. Signature: The testator's
                        signature must be present at the end of the will or in a
                        place where it is clear that the signature was intended
                        to authenticate the document.{" "}
                      </p>{" "}
                      <p>
                        {" "}
                        Probate Process: After the testator's death, the will is
                        submitted to probate court, where its validity is
                        determined. The court oversees the distribution of the
                        testator's assets according to the terms of the will.
                        Executor: The executor, named in the will, is
                        responsible for administering the estate. They gather
                        assets, pay debts and taxes, and distribute assets
                        according to the terms of the will. Challenges to the
                        Will: Probate law allows interested parties to challenge
                        the validity of the will if they believe it does not
                        meet the legal requirements or if they suspect undue
                        influence, fraud, or lack of testamentary capacity.
                        Intestate Succession: If someone dies without a valid
                        will (intestate), probate law dictates how their assets
                        will be distributed among their heirs according to the
                        laws of intestate succession, which vary by
                        jurisdiction. Distribution of Assets: Once all debts,
                        taxes, and expenses are paid, the remaining assets are
                        distributed to the beneficiaries named in the will. If
                        there are no beneficiaries or if the will does not
                        specify how assets should be distributed, they may be
                        distributed according to default rules established by
                        probate law.{" "}
                      </p>
                    </div>
                    <div className="slider-div">
                      <h2> Lawyer Lineup </h2>
                      <Slider {...settings}>
                        <div className="slider-width">
                          <Col className="lawyer-card relative">
                            <Row className="alignice">
                              <Col md={4} lg={4}>
                                {" "}
                                <img
                                  className="lawyerpic"
                                  src={lawyerlist}
                                />{" "}
                                <span className="rating circle-rate">
                                  {" "}
                                  4.5{" "}
                                  <FontAwesomeIcon
                                    className="staricon"
                                    icon={faStar}
                                  />{" "}
                                </span>{" "}
                              </Col>
                              <Col md={8} lg={8} className="martop">
                                {" "}
                                <h6> Keerthy P </h6>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faBriefcase}
                                  />{" "}
                                  5 + years
                                </p>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faLocationDot}
                                  />{" "}
                                  Coimbatore{" "}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <div className="slider-width">
                          <Col className="lawyer-card relative">
                            <Row className="alignice">
                              <Col md={4} lg={4}>
                                {" "}
                                <img
                                  className="lawyerpic"
                                  src={lawyerlist}
                                />{" "}
                                <span className="rating circle-rate">
                                  {" "}
                                  4.5{" "}
                                  <FontAwesomeIcon
                                    className="staricon"
                                    icon={faStar}
                                  />{" "}
                                </span>{" "}
                              </Col>
                              <Col md={8} lg={8} className="martop">
                                {" "}
                                <h6> Keerthy P </h6>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faBriefcase}
                                  />{" "}
                                  5 + years
                                </p>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faLocationDot}
                                  />{" "}
                                  Coimbatore{" "}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <div className="slider-width">
                          <Col className="lawyer-card relative">
                            <Row className="alignice">
                              <Col md={4} lg={4}>
                                {" "}
                                <img
                                  className="lawyerpic"
                                  src={lawyerlist}
                                />{" "}
                                <span className="rating circle-rate">
                                  {" "}
                                  4.5{" "}
                                  <FontAwesomeIcon
                                    className="staricon"
                                    icon={faStar}
                                  />{" "}
                                </span>{" "}
                              </Col>
                              <Col md={8} lg={8} className="martop">
                                {" "}
                                <h6> Keerthy P </h6>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faBriefcase}
                                  />{" "}
                                  5 + years
                                </p>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faLocationDot}
                                  />{" "}
                                  Coimbatore{" "}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <div className="slider-width">
                          <Col className="lawyer-card relative">
                            <Row className="alignice">
                              <Col md={4} lg={4}>
                                {" "}
                                <img
                                  className="lawyerpic"
                                  src={lawyerlist}
                                />{" "}
                                <span className="rating circle-rate">
                                  {" "}
                                  4.5{" "}
                                  <FontAwesomeIcon
                                    className="staricon"
                                    icon={faStar}
                                  />{" "}
                                </span>{" "}
                              </Col>
                              <Col md={8} lg={8} className="martop">
                                {" "}
                                <h6> Keerthy P </h6>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faBriefcase}
                                  />{" "}
                                  5 + years
                                </p>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faLocationDot}
                                  />{" "}
                                  Coimbatore{" "}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <div className="slider-width">
                          <Col className="lawyer-card relative">
                            <Row className="alignice">
                              <Col md={4} lg={4}>
                                {" "}
                                <img
                                  className="lawyerpic"
                                  src={lawyerlist}
                                />{" "}
                                <span className="rating circle-rate">
                                  {" "}
                                  4.5{" "}
                                  <FontAwesomeIcon
                                    className="staricon"
                                    icon={faStar}
                                  />{" "}
                                </span>{" "}
                              </Col>
                              <Col md={8} lg={8} className="martop">
                                {" "}
                                <h6> Keerthy P </h6>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faBriefcase}
                                  />{" "}
                                  5 + years
                                </p>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faLocationDot}
                                  />{" "}
                                  Coimbatore{" "}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <div className="slider-width">
                          <Col className="lawyer-card relative">
                            <Row className="alignice">
                              <Col md={4} lg={4}>
                                {" "}
                                <img
                                  className="lawyerpic"
                                  src={lawyerlist}
                                />{" "}
                                <span className="rating circle-rate">
                                  {" "}
                                  4.5{" "}
                                  <FontAwesomeIcon
                                    className="staricon"
                                    icon={faStar}
                                  />{" "}
                                </span>{" "}
                              </Col>
                              <Col md={8} lg={8} className="martop">
                                {" "}
                                <h6> Keerthy P </h6>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faBriefcase}
                                  />{" "}
                                  5 + years
                                </p>
                                <p>
                                  {" "}
                                  <FontAwesomeIcon
                                    className="faicons-chat"
                                    icon={faLocationDot}
                                  />{" "}
                                  Coimbatore{" "}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </Col>
                <Col lg={3} md={1}>
                  <div className="lawyerlist-container">
                    <h2> Lawyer lineup </h2>
                    <Col>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lawyerbox relative">
                        <Row>
                          <Col lg={4} md={4}>
                            <img className="lawyerpic" src={lawyerlist} />
                            <span className="circle-rate">
                              4.5
                              <FontAwesomeIcon
                                className="staricon"
                                icon={faStar}
                              />
                            </span>
                          </Col>
                          <Col lg={8} md={8} className="p-0">
                            <div className="lawyercardspace">
                              <h6> Keerthy P </h6>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faBriefcase}
                                />{" "}
                                5 + years
                              </p>
                              <p>
                                {" "}
                                <FontAwesomeIcon
                                  className="faicons-chat"
                                  icon={faLocationDot}
                                />{" "}
                                Coimbatore{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>{" "}
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default Openai;
