import "../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate, useParams } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faBars,
  faCircleXmark,
  faGear,
  faRightFromBracket,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import ailogo from "../assets/images/ailogo.png";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  classifyScheduler,
  chatSelector,
  saveHistory,
  clearhistoryData,
  historyScheduler,
} from "../store/reducer/ChatReducer";

function Chat() {
  const email = localStorage.getItem("email");
  const { chatkey } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** Ref Value */
  const aboutRef = useRef(null);
  /** Selectors */
  const { classifyData, historyData, historyViewData } =
    useSelector(chatSelector);

  const [question, setQuestion] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isShown, setIsShown] = useState(false);
  const [abShown, setabShown] = useState(false);
  const [cdShown, setcdShown] = useState(false);
  const [efShown, setefShown] = useState(false);
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  /** Functions */
  const handlerFuntion = (e) => {
    const { value } = e.target;
    setQuestion(value);
  };

  const handleQuery = async () => {
    try {
      setIsTyping(false);
      setCurrentIndex(0);
      const formData = {
        Received_data: question,
        email: email,
        history_id: chatkey,
      };

      dispatch(classifyScheduler({ formData }));
      setIsTyping(true);
      setQuestion("");
      setText("");
      setCurrentIndex(0);
      setIndex(0);
    } catch (error) {
      console.log(error);
    }
  };

  /** Scroll Down */

  const scrollToBottom = () => {
    console.log(aboutRef?.current, "TERS");
    if (aboutRef?.current) {
      aboutRef?.current.scrollIntoView({
        behavior: "smooth",
        bottom: 0,
      });
    }
  };

  /** Use Effects */
  useEffect(() => {
    dispatch(historyScheduler({ chatkey }));
  }, [chatkey, classifyData]);

  useEffect(() => {
    if (historyData !== "") {
      navigate(`/Chat/${historyData}`);
    }
  }, [historyData]);

  console.log(historyViewData, "historyViewData");

  useEffect(() => {
    if (
      classifyData?.prediction &&
      classifyData?.prediction != "" &&
      isTyping
    ) {
      if (text.length == classifyData?.prediction?.length) setText("");
      const timer = setTimeout(() => {
        const data = classifyData?.prediction
          ?.trim()
          ?.substring(0, currentIndex + 1);
        setCurrentIndex(currentIndex + 1);
        if (data?.length == classifyData?.prediction?.length)
          setIsTyping(false);

        if (index < data.length) {
          setText(data.slice(0, index) + data.charAt(index));
          setIndex((prevIndex) => prevIndex + 1);
        }
        scrollToBottom();
      }, 80);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentIndex, classifyData, isTyping]);

  console.log(index, "TESXTS");
  return (
    <>
      <section>
        <Row>
          <Col xl={2} lg={3} md={1} sm={12} className="p-0">
            <Sidebar> </Sidebar>
          </Col>
          <Col xl={10} lg={9} md={11} sm={12} className="p-0">
            <div className="black-bg relative">
              <Row>
                <Col lg={2} md={2}>
                  <div className="empty-div"></div>
                </Col>
                <Col lg={8} md={8}>
                  <div className="text-search">
                    <div className="chat-logo">
                      <img src={ailogo} /> <p>How can I help you? </p>
                    </div>
                    <hr />

                    <div className="searchbar-card">
                      <div className="scroll_update">
                        {historyViewData != "" ? (
                          <>
                            {historyViewData?.map((viewData, index) => {
                              return (
                                <div className="containers">
                                  <h3 className="fw-bolder">
                                    {viewData?.message?.question}
                                  </h3>
                                  {console.log(
                                    index + 1 == historyViewData?.length,
                                    historyViewData,
                                    "index1"
                                  )}
                                  {index + 1 == historyViewData?.length ? (
                                    <>
                                      {classifyData?.prediction ? (
                                        <>
                                          <p
                                            ref={aboutRef}
                                            className="typewriter fontSize"
                                          >
                                            {text}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className="fontSize">
                                            {viewData?.message?.prediction}
                                          </p>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <p className="fontSize">
                                      {viewData?.message?.prediction}
                                    </p>
                                  )}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <Row className="mt-5 ">
                              <Col md={6} lg={6}>
                                <div
                                  className="card-bg relative"
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                >
                                  <p>
                                    Can you discuss the legal of landlords and
                                    tenants in a residential lease agreement?
                                  </p>
                                  {isShown && (
                                    <FontAwesomeIcon
                                      className="send-button"
                                      icon={faArrowUp}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col md={6} lg={6}>
                                <div
                                  className="card-bg relative"
                                  onMouseEnter={() => setabShown(true)}
                                  onMouseLeave={() => setabShown(false)}
                                >
                                  <p>
                                    How does the concept of "beyond a reasonable
                                    doubt" apply in criminal cases?
                                  </p>
                                  {abShown && (
                                    <FontAwesomeIcon
                                      className="send-button"
                                      icon={faArrowUp}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col md={6} lg={6}>
                                <div
                                  className="card-bg relative"
                                  onMouseEnter={() => setcdShown(true)}
                                  onMouseLeave={() => setcdShown(false)}
                                >
                                  <p>
                                    Can you explain the difference between a
                                    misdemeanor and a felony Lorem ?
                                  </p>
                                  {cdShown && (
                                    <FontAwesomeIcon
                                      className="send-button"
                                      icon={faArrowUp}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col md={6} lg={6}>
                                <div
                                  className="card-bg relative"
                                  onMouseEnter={() => setefShown(true)}
                                  onMouseLeave={() => setefShown(false)}
                                >
                                  <p>
                                    How does the legal system handle cases
                                    involving minors or juveniles loremepsum ?
                                  </p>
                                  {efShown && (
                                    <FontAwesomeIcon
                                      className="send-button"
                                      icon={faArrowUp}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                      <div className="searchbar-div">
                        <Row className="msclass">
                          <Col md={10} className="aitext-center">
                            <Form.Control
                              className="border-none"
                              onChange={(e) => {
                                handlerFuntion(e);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleQuery();
                                }
                              }}
                              type="text"
                              placeholder="Message AI Lawyer"
                              value={question}
                            />
                          </Col>
                          <Col>
                            <button
                              disabled={isTyping}
                              style={{ width: "50px" }}
                              className="enter-button"
                              onClick={handleQuery}
                            >
                              <FontAwesomeIcon icon={faArrowUp} />
                            </button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={2} md={2}></Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default Chat;
