import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/responsive.css";
import ailogo from "../assets/images/ailogo.png";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import Sidebar from "./Sidebar";
import {
    faArrowUp,
    faCircleXmark,
    faGear,
    faRightFromBracket,
    faUser,
    faXmark,
    faSuitcase,
    faLocationDot,
    faEnvelope,
    faPhone,
    faBars
  } from "@fortawesome/free-solid-svg-icons";
  import { useState } from "react";
  import profileimg from "../assets/images/profileimg.png";
  import rating from "../assets/images/rating.png";
  import bgimg from "../assets/images/bgimg.jpg";
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Case
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
          In a complex property dispute, Sarah Rodriguez's strategic expertise secured rightful ownership, resolving the conflict swiftly. In legal parlance, a "property case" refers to a legal dispute or matter pertaining to the ownership, possession, or rights associated with real or personal property. These cases encompass a broad range of issues, including conflicts over land boundaries, disagreements concerning property titles, landlord-tenant disputes, and infringement of intellectual property rights. For instance, a property case might involve resolving a disagreement between neighbors regarding the exact location of a property line or addressing a dispute between a landlord and a tenant over lease terms or maintenance responsibilities. Similarly, property cases can extend to complex matters such as trademark infringement or disputes over the ownership of artistic works. To settle property cases, various legal avenues are available, including negotiation, mediation, arbitration, or litigation in court. Each case is unique, demanding a thorough understanding of property laws and relevant regulations to achieve a fair and just resolution for all parties involved.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

function File (){
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [menushow, setmenuShow] = useState(false);
    const handleClose = () => setShow(false);
  const handlemenuShow = () => setmenuShow(!menushow);
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return(
        <div>

        </div>
     );
    }
    export default File;