import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
const PrivateRoute = () => {
  const isAuth = localStorage.getItem("isAuthenticated");
  if (!isAuth) {
    localStorage.clear();
  }
  console.log(isAuth, "isAuth");
  return isAuth ? (
    <>
      <main>
        <Row className="h-100">
          <Col className="h-100" id="contentArea">
            <Outlet />
          </Col>
        </Row>
      </main>
    </>
  ) : (
    <Navigate to="/Login" />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PrivateRoute);
