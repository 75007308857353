import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "login", formData);
};

export const signUp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "signup", formData);
};
export const classify = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(formData, "sjifkshdi");
  return Http.post(baseUrl + "classify", formData);
};

export const savehistory = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "save_history", formData);
};

export const chat_history = (email) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "retrieve_history/" + email, {});
};

export const history_view = (chatkey) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "chat_history/" + chatkey, {});
};

export const lawyer_signUp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "lawyer_signup", formData);
};

export const lawyer_signIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "lawyer_login", formData);
};


