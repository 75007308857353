import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik, Field } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
/** Imports from Local file */
import "../assets/css/style.css";
import ailogo from "../assets/images/ailogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  lawyerSignUpScheduler,
  signInSelector,
  clearData,
} from "../store/reducer/SignInConfigureReducer";
import { API_STATUS } from "../utils/constant";
import { lawyersignUpValidationSchema } from "../utils/validation";

function LawyerSignup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** States */
  const [validated, setValidated] = useState(false);
  /** Selector */
  const { lawyerSignUpLoading, errorMessage } = useSelector(signInSelector);

  /** Form Submit */
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    password: "",
    address: "",
    specialization: "",
    years_of_experience: "",
    handled_cases: "",
  };
  const onSubmit = (formData) => {    
    dispatch(lawyerSignUpScheduler({ formData }));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: lawyersignUpValidationSchema,
    onSubmit,
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;

  /** Message For Status */
  useEffect(() => {
    if (lawyerSignUpLoading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Sign Up in Successfully!");
      dispatch(clearData());
      navigate("/LawyerLogin");
    }
    if (lawyerSignUpLoading === API_STATUS.REJECTED) {
      toastr.clear();
      toastr.error(errorMessage);
      dispatch(clearData());
    }
  }, [lawyerSignUpLoading, errorMessage]);

  return (
    <div>
      <section>
        <Row>
          <Col lg={6} md={6} className="">
            <div className="dark-bg justify-content">
              <Row>
                <Col lg={12} md={12}>
                  <div className="logo-img">
                    <img src={ailogo} />
                  </div>
                </Col>
                <Col lg={12} md={12}>
                  <div className="signup-box">
                    <FontAwesomeIcon
                      onClick={(e) => {
                        navigate("/LawyerLogin");
                      }}
                      className="icon"
                      icon={faArrowLeft}
                    />
                    <h1> Welcome Let's Get Started </h1>
                    <p>
                      Please enter your sign up details. If you are a member
                      <span
                        className="linktext"
                        onClick={(e) => {
                          navigate("/LawyerLogin");
                        }}
                      >
                        Login
                      </span>
                    </p>
                    <Form
                      id="signupForm"
                      className={
                        validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                      }
                      onSubmit={(e) => {
                        e.preventDefault();
                        setValidated(true);
                        handleSubmit();
                      }}
                    >
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="textwhite">Name</Form.Label>
                            <Form.Control
                              className="textspace"
                              type="text"
                              name="name"
                              placeholder="Enter Name"
                              onChange={handleChange}
                              value={values.name}
                            />
                            <p className="text-danger">{errors.name}</p>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="textwhite">
                              Mobile
                            </Form.Label>
                            <Form.Control
                              className="textspace"
                              type="number"
                              name="mobile"
                              placeholder="Enter Mobile"
                              onChange={handleChange}
                              value={values.mobile}
                            />
                            <p className="text-danger">{errors.mobile}</p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="textwhite">
                              Email address
                            </Form.Label>
                            <Form.Control
                              className="textspace"
                              type="email"
                              name="email"
                              placeholder="Enter Email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            <p className="text-danger">{errors.email}</p>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Label
                            className="textwhite"
                            htmlFor="inputPassword5"
                          >
                            Password
                          </Form.Label>
                          <Form.Control
                            className="textspace"
                            type="password"
                            name="password"
                            id="inputPassword5"
                            placeholder="Enter Password"
                            aria-describedby="passwordHelpBlock"
                            onChange={handleChange}
                            value={values.password}
                          />
                          <p className="text-danger">{errors.password}</p>
                          <Form.Text id="passwordHelpBlock" muted></Form.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="textwhite">
                              Specialization
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="specialization"
                              placeholder="specialization"
                              className="textspace"
                              onChange={handleChange}
                            />
                            <p className="text-danger">
                              {errors.specialization}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="textwhite">
                              Years of Experience
                            </Form.Label>
                            <Form.Control
                              className="textspace"
                              type="number"
                              name="years_of_experience"
                              placeholder="Enter Years Of Experience"
                              onChange={handleChange}
                              value={values.years_of_experience}
                            />
                            <p className="text-danger">
                              {errors.years_of_experience}
                            </p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="textwhite">
                              Handled Cases
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="handled_cases"
                              placeholder="Handled Cases"
                              className="textspace"
                              onChange={handleChange}
                            />
                            <p className="text-danger">
                              {errors.handled_cases}
                            </p>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Label className="textwhite" htmlFor="address">
                            Address
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="address"
                            id="address"
                            placeholder="Address"
                            className="textspace"
                            onChange={handleChange}
                          />
                          <p className="text-danger">{errors.address}</p>
                        </Col>
                      </Row>
                      <Button className="mb-1" type="submit">SignUp </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={6} className="p-0">
            <div className="puple-bg">
              <Row>
                <Col lg={1} md={1}></Col>
                <Col lg={9} md={9}>
                  <div className="supporttext">
                    <h2>Let's Find Out How I Can Support You ? </h2>
                  </div>
                </Col>
                <Col lg={1} md={1}></Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}
export default LawyerSignup;
