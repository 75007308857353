import * as yup from "yup";

const validateEmail = (email) => {
  return yup.string().email().isValidSync(email);
};

export const signInValidationSchema = yup.object().shape({
  emailOrMobile: yup.string().required("Mobile / Email is required"),
  password: yup.string().required("Password is required"),
});

export const signUpValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  mobile: yup.string().required("Mobile is required"),
  password: yup.string().required("Password is required"),
});

export const lawyersignUpValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  mobile: yup.number().required("Mobile is required"),
  password: yup.string().required("Password is required"),
  address: yup.string(),
  specialization: yup.string().required("Specializtion is required"),
  years_of_experience: yup.string().required("Year Of Experience is required"),
  handled_cases: yup.string().required("Handled Cases is required"),
});
