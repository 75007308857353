import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constant";
import { signIn, signUp, lawyer_signUp,lawyer_signIn } from "../../services/api";
import { secretKey } from "../../services/config";
const namespace = "signInConfiguration";

const initialState = {
  signInerrorMessage: "",
  signInData: [],
  signUpData: [],
  signInLoading: "initial",
  signUpLoading: "initial",
  lawyerSignUpLoading: "initial",
  lawyerSignInLoading: "initial",
};

export const signInScheduler = createAsyncThunk(
  `${namespace}/signInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData1");
      const { data } = await signIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const signUpScheduler = createAsyncThunk(
  `${namespace}/signUpScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "signUp_data");
      const { data } = await signUp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const lawyerSignUpScheduler = createAsyncThunk(
  `${namespace}/lawyerSignUpScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "signUp_data");
      const { data } = await lawyer_signUp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const lawyerSignInScheduler = createAsyncThunk(
  `${namespace}/lawyerSignInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "signUp_data");
      const { data } = await lawyer_signIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const signInConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignInData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.signInData = null;
      state.signInLoading = "initial";
      state.signUpLoading = "initial";
      state.lawyerSignUpLoading = "initial";
      state.lawyerSignInLoading = "initial";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(signInScheduler.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInScheduler.fulfilled, (state, { payload }) => {
      state.signInLoading = API_STATUS.FULFILLED;
      const payloadData = payload?.user_data;
      state.signInData = payloadData;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payloadData?.Name);
      localStorage.setItem("email", payloadData?.Email);
    });
    builder.addCase(signInScheduler.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.signInerrorMessage = action?.payload?.data?.message;
      console.log(state.signInerrorMessage, "LOGIN_ERROR");
    });
    builder.addCase(signUpScheduler.pending, (state) => {
      state.signUpLoading = API_STATUS.PENDING;
    });
    builder.addCase(signUpScheduler.fulfilled, (state, { payload }) => {
      state.signUpLoading = API_STATUS.FULFILLED;
      state.signUpData = payload;
    });
    builder.addCase(signUpScheduler.rejected, (state, action) => {
      state.signUpLoading = API_STATUS.REJECTED;
      console.log(action?.payload, "payload");
      state.signInerrorMessage = action?.payload?.data?.message;
      console.log(action?.error?.message, "signUP_ERROR");
    });
    builder.addCase(lawyerSignUpScheduler.pending, (state) => {
      state.lawyerSignUpLoading = API_STATUS.PENDING;
    });
    builder.addCase(lawyerSignUpScheduler.fulfilled, (state, { payload }) => {
      state.lawyerSignUpLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(lawyerSignUpScheduler.rejected, (state, action) => {
      state.lawyerSignUpLoading = API_STATUS.REJECTED;
      console.log(action?.payload, "payload");
      state.signInerrorMessage = action?.payload?.data?.message;
      console.log(action?.error?.message, "signUP_ERROR");
    });
    builder.addCase(lawyerSignInScheduler.pending, (state) => {
      state.lawyerSignInLoading = API_STATUS.PENDING;
    });
    builder.addCase(lawyerSignInScheduler.fulfilled, (state, { payload }) => {
      state.lawyerSignInLoading = API_STATUS.FULFILLED;
      const payloadData = payload?.user_data;
      state.signInData = payloadData;
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payloadData?.Name);
      localStorage.setItem("email", payloadData?.Email);
    });
    builder.addCase(lawyerSignInScheduler.rejected, (state, action) => {
      state.lawyerSignInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.signInerrorMessage = action?.payload?.data?.message;
      console.log(state.signInerrorMessage, "LOGIN_ERROR");
    });
  },
});

export const { clearSignInData, clearData } = signInConfigSlice.actions;

export const signInSelector = (state) => state.signInConfiguration;

export default signInConfigSlice.reducer;
