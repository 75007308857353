import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/constant";
import {
  classify,
  savehistory,
  chat_history,
  history_view,
} from "../../services/api";
import { secretKey } from "../../services/config";
const namespace = "ChatConfiguration";

const initialState = {
  errorMessage: "",
  classifyData: "",
  classifyLastData: "",
  retrieveData: "",
  historyViewData: "",
  classifyDataLoading: "initial",
  retrieveDataLoading: "initial",
  historyViewDataLoading: "initial",
  historyData: "",
};

export const classifyScheduler = createAsyncThunk(
  `${namespace}/classifyScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData1");
      const { data } = await classify(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const saveHistory = createAsyncThunk(
  `${namespace}/saveHistory`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData2");
      const { data } = await savehistory(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const retrieveHistroy = createAsyncThunk(
  `${namespace}/retrieveHistroy`,
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      console.log(email, "email");
      const { data } = await chat_history(email);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const historyScheduler = createAsyncThunk(
  `${namespace}/historyScheduler`,
  async ({ chatkey }, { rejectWithValue, dispatch }) => {
    try {
      console.log(chatkey, "resu");
      const { data } = await history_view(chatkey);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const chatSlicer = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearretrieveData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.classifyData = null;
      state.classifyDataLoading = "initial";
      state.historyViewDataLoading = "initial";
      state.retrieveDataLoading = "initial";
    },
    clearhistoryData: (state) => {
      state.historyData = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(classifyScheduler.pending, (state) => {
      state.classifyDataLoading = API_STATUS.PENDING;
    });
    builder.addCase(classifyScheduler.fulfilled, (state, { payload }) => {
      state.classifyDataLoading = API_STATUS.FULFILLED;
      const payloadData = {
        prediction: payload?.prediction,
        previous: payload?.prev,
        last: payload?.last,
      };
      state.historyData = payload?.history_id;
      state.classifyData = payloadData;
    });
    builder.addCase(classifyScheduler.rejected, (state, action) => {
      state.classifyDataLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.error?.message;
    });
    builder.addCase(saveHistory.pending, (state) => {
      state.saveHistoryLoading = API_STATUS.PENDING;
    });
    builder.addCase(saveHistory.fulfilled, (state, { payload }) => {
      state.saveHistoryLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(saveHistory.rejected, (state, action) => {
      state.saveHistoryLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.error?.message;
    });
    builder.addCase(retrieveHistroy.pending, (state) => {
      state.retrieveDataLoading = API_STATUS.PENDING;
    });
    builder.addCase(retrieveHistroy.fulfilled, (state, { payload }) => {
      state.retrieveDataLoading = API_STATUS.FULFILLED;
      state.retrieveData = payload?.first_asked;
      console.log(state.retrieveData, "PAYLOADSD");
    });
    builder.addCase(retrieveHistroy.rejected, (state, action) => {
      state.retrieveDataLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.error?.message;
    });
    builder.addCase(historyScheduler.pending, (state) => {
      state.historyViewDataLoading = API_STATUS.PENDING;
    });
    builder.addCase(historyScheduler.fulfilled, (state, { payload }) => {
      state.historyViewDataLoading = API_STATUS.FULFILLED;
      state.historyViewData = payload?.history?.history;
      console.log(state.historyViewData, "PAYLOADSD");
    });
    builder.addCase(historyScheduler.rejected, (state, action) => {
      state.historyViewDataLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.error?.message;
    });
  },
});

export const { clearretrieveData, clearData, clearhistoryData } =
  chatSlicer.actions;

export const chatSelector = (state) => state.ChatConfiguration;

export default chatSlicer.reducer;
