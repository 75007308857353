import { Col, Container, Row } from "react-bootstrap";
import "../assets/css/profile.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/responsive.css";
import ailogo from "../assets/images/ailogo.png";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import Sidebar from "./Sidebar";
import {
    faArrowUp,
    faCircleXmark,
    faGear,
    faRightFromBracket,
    faUser,
    faXmark,
    faSuitcase,
    faLocationDot,
    faEnvelope,
    faPhone,
    faBars
  } from "@fortawesome/free-solid-svg-icons";
  import { useState } from "react";
  import profileimg from "../assets/images/profileimg.png";
  import rating from "../assets/images/rating.png";
  import bgimg from "../assets/images/bgimg.jpg";
  import Slider from "react-slick";
  import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Property Case
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
          In a complex property dispute, Sarah Rodriguez's strategic expertise secured rightful ownership, resolving the conflict swiftly. In legal parlance, a "property case" refers to a legal dispute or matter pertaining to the ownership, possession, or rights associated with real or personal property. These cases encompass a broad range of issues, including conflicts over land boundaries, disagreements concerning property titles, landlord-tenant disputes, and infringement of intellectual property rights. For instance, a property case might involve resolving a disagreement between neighbors regarding the exact location of a property line or addressing a dispute between a landlord and a tenant over lease terms or maintenance responsibilities. Similarly, property cases can extend to complex matters such as trademark infringement or disputes over the ownership of artistic works. To settle property cases, various legal avenues are available, including negotiation, mediation, arbitration, or litigation in court. Each case is unique, demanding a thorough understanding of property laws and relevant regulations to achieve a fair and just resolution for all parties involved.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

function Profile (){
    const [show, setShow] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [menushow, setmenuShow] = useState(false);
    const handleClose = () => setShow(false);
  const handlemenuShow = () => setmenuShow(!menushow);
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    return(
        <div>
            <section>
                <Row>
                    <Col md={1} lg={2} sm={1} className="p-0">
                        <Sidebar></Sidebar>
                    </Col>

                    <Col lg={10} md={11} sm={11} className="p-0">
                           <div className="black-bg">
                           <div className="bottom">
                                <div className="bg-lawyer"></div>
                            </div>
                        <Container className="width">
                           <div>
                            <div className="profile-head">
                            <Row className="profile-section">
                                <Col lg={3} md={3} sm={3} className="profile-top">
                                    <div className="profile-img">
                                        <img src={profileimg}/>
                                    </div>
                                    <div className="rate">
                                       <img src={rating}/>
                                    </div>
                                    <div className="number">
                                        <h3>4.0</h3>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={3} className="top-1">
                                    <div className="name">
                                        <div>
                                            <h2>Keerthy P</h2>
                                        </div>
                                        <div className="details">
                                            <ul>
                                                <li><FontAwesomeIcon className="details-icon" icon={faSuitcase} style={{color: "#421781",}} /><p>5+ years of experience</p></li>
                                                <li><FontAwesomeIcon className="details-icon map" icon={faLocationDot} style={{color: "#421781",}} /><p>Coimbatore</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={3} className="top-2">
                                    <div className="details mail">
                                        <ul>
                                            <li><FontAwesomeIcon className="details-icon" icon={faEnvelope} style={{color: "#421781",}} /><p>name@gmail.com</p></li>
                                            <li><FontAwesomeIcon className="details-icon" icon={faPhone} style={{color: "#421781",}} /><p>+91 12345 67890</p></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            </div>
                           </div>

                            <Row className="areas">
                                <Col xl={5} lg={6} md={12} sm={12}>
                                   <div className="about">
                                   <div>
                                        <h2>Practice areas:</h2>
                                        <p>As an AI lawyer, our practice encompasses a diverse range of legal areas tailored to meet the needs of businesses and individuals in today's complex world. We specialize in Intellectual Property Law, offering services such as trademark registration, patent drafting, and copyright protection. Our expertise extends to Contract Law, where we excel in drafting, reviewing, and negotiating contracts, as well as managing contract lifecycles.</p>
                                    </div>
                                   </div>
                                </Col>
                                <Col xl={5} lg={6} md={12} sm={12}>
                                   <div className="about">
                                   <div>
                                        <h2>Lawyer Details:</h2>
                                        <p>Sarah Rodriguez is a passionate advocate known for her unwavering commitment to justice and her empathetic approach to client representation. With over a decade of experience practicing law, Sarah earned her Juris Doctor degree from Harvard Law School, where she graduated with honors. Born and raised in a small town in Texas, Sarah's upbringing instilled in her a strong sense of community.</p>
                                    </div>
                                   </div>
                                </Col>
                            </Row>

                            <div className="advocate">
                            <div className="head">
                                <h2>Advocate Keerthy Case study List</h2>
                            </div>

                            <Row className="advocate-cards">
                            <div className="slider-container">
                            <Slider {...settings}>
                                <Col lg={3} md={3} sm={3}>
                                    <>
                                    <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Property Case</h4>
                                        <p>In a complex property dispute, Sarah Rodriguez's strategic expertise secured rightful ownership, resolving the conflict swiftly.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                    </>
                                    
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                   <>
                                   <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Legal Victory</h4>
                                        <p>In a landmark case, Sarah Rodriguez secured justice for victims of workplace discrimination, demonstrating her unwavering commitment.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                   </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                   <>
                                   <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Human Rights</h4>
                                        <p>Sarah Rodriguez's relentless advocacy led to a transformative legal victory, reaffirming the importance of upholding human rights in the workplace.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                   </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                   <>
                                   <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Equality Advocate</h4>
                                        <p>Sarah Rodriguez's legal triumph embodies her dedication to promoting equality and social justice, setting a precedent for future workplace.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                   </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                    <>
                                    <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Justice Advocate</h4>
                                        <p>Sarah Rodriguez's impactful litigation underscored her dedication to justice, empowering marginalized individuals.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                    </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                   <>
                                   <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Equality Advocate</h4>
                                        <p>Sarah Rodriguez's legal acumen and passion for justice shone brightly in her successful defense of workplace equality, inspiring change and fostering inclusivity.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                   </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                    <>
                                    <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Champion</h4>
                                        <p>Sarah Rodriguez: Tireless advocate, dedicated to justice, empowering the marginalized through legal prowess and equality in society.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                    </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                   <>
                                   <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Trailblazer</h4>
                                        <p>Sarah Rodriguez: Legal pioneer, breaking barriers, forging new paths towards equality, justice, equality in society shaping a brighter future for all.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                   </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                   <>
                                   <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Change Catalyst</h4>
                                        <p>Sarah Rodriguez's landmark victory serves as a testament to her role as a catalyst for change, driving forward the pursuit of justice and equality in society.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                   </>
                                </Col>
                                <Col lg={3} md={3} sm={3}>
                                    <>
                                    <div className="box" onClick={() => setModalShow(true)}>
                                        <h4>Justice Warrior</h4>
                                        <p>Sarah Rodriguez's legal triumph epitomizes her status as a warrior for justice, fearlessly defending the rights of the marginalized.</p>
                                    </div>
                                    <MyVerticallyCenteredModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                    />
                                    </>
                                </Col>
                            </Slider>
                            </div>
                            </Row>
                        </div>

                        </Container>
                           </div>
                    </Col>
                </Row>

            </section>
        </div>
        
    );
}
export default Profile;