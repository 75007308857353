import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { signInValidationSchema } from "../utils/validation";
import {
  signInScheduler,
  signInSelector,
  clearData,
} from "../store/reducer/SignInConfigureReducer";
import "../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { API_STATUS } from "../utils/constant";
import ailogo from "../assets/images/ailogo.png";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** States */
  const [validated, setValidated] = useState(false);

  /** Selectors */
  const { signInLoading, signInerrorMessage } = useSelector(signInSelector);
  
  /** Submit the form to login */

  const initialValues = { emailOrMobile: "", password: "" };

  const onSubmit = (formData) => {
    dispatch(signInScheduler({ formData }));
  };

  /** Form Data */
  const formik = useFormik({ initialValues, validationSchema: signInValidationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  console.log(errors, "ERRORS1");
  /** Message For Status */
  useEffect(() => {
    if (signInLoading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Logged in Successfully!");
      dispatch(clearData());
      navigate("/Chat");
    }
    if (signInLoading === API_STATUS.REJECTED) {
      toastr.clear();
      toastr.error(signInerrorMessage);
      console.log(signInerrorMessage,"signInerrorMessage")
      dispatch(clearData());
    }
  }, [signInLoading, signInerrorMessage]);

  return (
    <>
      <section>
        <Row>
          <Col lg={4} md={6} className="p-0">
            <div className="dark-bg justify-content">
              <Row>
                <Col lg={12} md={12}>
                  <div className="logo-img">
                    {" "}
                    <img src={ailogo} />{" "}
                  </div>
                </Col>
                <Col lg={12} md={12}>
                  <div className="signup-box">
                    <FontAwesomeIcon
                      onClick={(e) => {
                        navigate("/");
                      }}
                      className="icon"
                      icon={faArrowLeft}
                    />
                    <h1> Welcome Let's Get Started </h1>
                    <p>
                      {" "}
                      Please use your credentials to login. If you are not a
                      member, please{" "}
                      <span
                        className="linktext"
                        onClick={(e) => {
                          navigate("/Signup");
                        }}
                      >
                        {" "}
                        Signup{" "}
                      </span>
                    </p>
                    <Form
                      id="loginForm"
                      className={
                        validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                      }
                      onSubmit={(e) => {
                        e.preventDefault();
                        setValidated(true);
                        handleSubmit();
                      }}
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="textwhite">
                          Email / Mobile address
                        </Form.Label>
                        <Form.Control
                          className="textspace"
                          type="text"
                          name="emailOrMobile"
                          value={values.emailOrMobile}
                          placeholder="Enter Mobile / Email"
                          onChange={handleChange}
                        />
                        <p className="text-danger">{errors.emailOrMobile}</p>
                      </Form.Group>
                      <Form.Label
                        className="textwhite"
                        htmlFor="inputPassword5"
                      >
                        Password
                      </Form.Label>
                      <Form.Control
                        className="textspace"
                        type="password"
                        name="password"
                        id="inputPassword5"
                        value={values.password}
                        aria-describedby="passwordHelpBlock"
                        onChange={handleChange}
                      />
                      <p className="text-danger">{errors.password}</p>
                      <Form.Text id="passwordHelpBlock" muted></Form.Text>
                      <Button type="submit"> Login </Button>{" "}
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} md={6} className="p-0">
            <div className="puple-bg">
              <Row>
                <Col lg={1} md={1}>
                  {" "}
                </Col>
                <Col lg={9} md={9}>
                  {" "}
                  <div className="supporttext">
                    {" "}
                    <h2>Let's Find Out How I Can Support You ? </h2>{" "}
                  </div>{" "}
                </Col>
                <Col lg={1} md={1}>
                  {" "}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default Login;
