import "../assets/css/style.css";
import "../assets/css/responsiveweb.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ailogo from "../assets/images/ailogo.png";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

function Intro() {
  const navigate = useNavigate();

  return (
    <>
      <section className="intro-sec">
        <Row>
          <Col lg={4} md={6} className="p-0">
            <div className="dark-bg justify-content">
              <Row>
                <Col lg={12} md={12}>
                <div className="logo-img">
                  {" "}
                  <img src={ailogo} />{" "}
                </div>
                </Col>
                <Col lg={12} md={12}>
                  {" "}
                  <div className="introcentertext">
                    <h1> Get Started </h1>
                    <Button
                      onClick={(e) => {
                        navigate("/Login");
                      }}
                    >
                      {" "}
                      Login{" "}
                    </Button>
                    <Button
                      onClick={(e) => {
                        navigate("/Signup");
                      }}
                    >
                      {" "}
                      Sign up{" "}
                    </Button>
                  </div>{" "}
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} md={6} className="p-0">
            <div className="puple-bg">
              <Row>
              <Col lg={1} md={1}> </Col>
              <Col lg={9} md={9}> <div className="supporttext"> <h2>Let's Find Out How I Can Support You ? </h2>{" "}</div> </Col>
              <Col lg={1} md={1}> </Col>
              </Row>
              
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default Intro;
