import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useFormik, Field } from "formik";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
/** Imports from Local file */
import "../assets/css/style.css";
import ailogo from "../assets/images/ailogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  signUpScheduler,
  signInSelector,
  clearData,
} from "../store/reducer/SignInConfigureReducer";
import { API_STATUS } from "../utils/constant";
import { signUpValidationSchema } from "../utils/validation";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** States */
  const [validated, setValidated] = useState(false);
  /** Selector */
  const {
    signUpData,
    signUpLoading,
    collegeData,
    collegeLoading,
    errorMessage,
  } = useSelector(signInSelector);

  /** Form Submit */
  const initialValues = { name: "", email: "", mobile: "", password: "" };
  const onSubmit = (formData) => {
    dispatch(signUpScheduler({ formData }));
  };

  const formik = useFormik({ initialValues, validationSchema : signUpValidationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  

  /** Message For Status */
  useEffect(() => {
    if (signUpLoading === API_STATUS.FULFILLED) {
      toastr.clear();
      toastr.success("Sign Up in Successfully!");
      dispatch(clearData());
      navigate("/Login");
    }
    if (signUpLoading === API_STATUS.REJECTED) {
      toastr.clear();
      toastr.error(errorMessage);
      dispatch(clearData());
    }
  }, [signUpLoading, errorMessage]);

  return (
    <div>
      <section>
        <Row>
          <Col lg={4} md={6} className="p-0">
            <div className="dark-bg justify-content">
              <Row>
                <Col lg={12} md={12}>
                  <div className="logo-img">
                    {" "}
                    <img src={ailogo} />{" "}
                  </div>
                </Col>
                <Col lg={12} md={12}>
                  <div className="signup-box">
                    <FontAwesomeIcon
                      onClick={(e) => {
                        navigate("/");
                      }}
                      className="icon"
                      icon={faArrowLeft}
                    />
                    <h1> Welcome Let's Get Started </h1>
                    <p>
                      {" "}
                      Please use your credentials to login. If you are not a
                      member, please{" "}
                      <span
                        className="linktext"
                        onClick={(e) => {
                          navigate("/Login");
                        }}
                      >
                        {" "}
                        Login{" "}
                      </span>
                    </p>
                    <Form
                      id="signupForm"
                      className={
                        validated ? "tooltip-end-bottom" : "tooltip-end-bottom "
                      }
                      onSubmit={(e) => {
                        e.preventDefault();
                        setValidated(true);
                        handleSubmit();
                      }}
                    >
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="textwhite"> Name </Form.Label>
                        <Form.Control
                          className="textspace"
                          type="name"
                          name="name"
                          placeholder="Enter Name"
                          onChange={handleChange}
                          value={values.name}
                        />
                        <p className="text-danger">{errors.name}</p>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="textwhite"> Mobile </Form.Label>
                        <Form.Control                          
                          className="textspace"
                          type="number"
                          name="mobile"
                          placeholder="Enter Mobile"
                          onChange={handleChange}
                          value={values.mobile}
                        />
                        <p className="text-danger">{errors.mobile}</p>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="textwhite">
                          Email address
                        </Form.Label>
                        <Form.Control                          
                          className="textspace"
                          type="email"
                          name="email"
                          placeholder="Enter Email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <p className="text-danger">{errors.email}</p>
                      </Form.Group>
                      <Form.Label
                        className="textwhite"
                        htmlFor="inputPassword5"
                      >
                        Password
                      </Form.Label>
                      <Form.Control                        
                        className="textspace"
                        type="password"
                        name="password"
                        id="inputPassword5"
                        placeholder="Enter Password"
                        aria-describedby="passwordHelpBlock"
                        onChange={handleChange}
                        value={values.password}
                      />
                      <p className="text-danger">{errors.password}</p>
                      <Form.Text id="passwordHelpBlock" muted></Form.Text>
                      <Button type="submit">SignUp </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={8} md={6} className="p-0">
            <div className="puple-bg">
              <Row>
                <Col lg={1} md={1}>
                  {" "}
                </Col>
                <Col lg={9} md={9}>
                  {" "}
                  <div className="supporttext">
                    {" "}
                    <h2>Let's Find Out How I Can Support You ? </h2>{" "}
                  </div>{" "}
                </Col>
                <Col lg={1} md={1}>
                  {" "}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}
export default Signup;
