import { configureStore } from "@reduxjs/toolkit";
import signInConfiguration from "./reducer/SignInConfigureReducer";
import ChatReducer from "./reducer/ChatReducer";
export const reducer = {
  signInConfiguration: signInConfiguration,
  ChatConfiguration: ChatReducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
